import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { hookUtils } from '@indico-data/utils';
import { Button } from 'Permafrost/index';
import { Icon } from 'Permafrost/index';
import { StyledFilter } from './Filter.styles';
import { isEqual } from 'lodash';
import { IconName } from 'Permafrost/index';

export type FilterOption = {
  text: string;
  value: string;
  iconName?: IconName;
};

export type FilterProps = {
  isMulti?: boolean;
  optionsList: FilterOption[];
  filter: FilterOption[];
  onFilter(options: FilterOption[]): void;
  placement?: 'left' | 'right';
};

export const Filter = (props: FilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { filter, onFilter, optionsList, isMulti = false, placement = 'right' } = props;
  const menuEl = useRef() as React.MutableRefObject<HTMLDivElement>;

  const hasFilter = !!filter.length;

  const handleToggleMultiFilter = (option: FilterOption) => {
    if (filter.some((filterOption) => isEqual(filterOption, option))) {
      onFilter(filter.filter((filterOption) => !isEqual(filterOption, option)));
    } else {
      onFilter([...filter, option]);
    }
  };

  const handleToggleSingleFilter = (option: FilterOption) => {
    isEqual(filter[0], option) && hasFilter ? onFilter([]) : onFilter([option]);
  };

  const handleOptionClick = (option: FilterOption) => {
    isMulti ? handleToggleMultiFilter(option) : handleToggleSingleFilter(option);
  };

  hookUtils.useClickOutside(menuEl, () => {
    setIsOpen(false);
  });

  return (
    <StyledFilter ref={menuEl} $placement={placement} className="filter">
      <Button
        variant="outline"
        className={classNames('filter__toggle', 'square', {
          'filters-on': hasFilter,
        })}
        data-testid="button-filter-toggle"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Icon name="filter-outline" />
        {hasFilter ? <span className="swatch-circle" /> : []}
      </Button>
      <div
        data-testid="div-filter-options-container"
        className={classNames('filter__container', {
          visible: isOpen,
        })}
      >
        {hasFilter ? (
          <p
            className="filter__clear-filter"
            onClick={() => {
              onFilter([]);
            }}
          >
            Clear Filter
          </p>
        ) : (
          []
        )}
        <div className="filter__options">
          <h4 className="filter__title">FILTER ON</h4>
          <ul>
            {optionsList.map((filterOption: FilterOption) => {
              const { text, value, iconName } = filterOption;

              const selected = filter.some((item: FilterOption) => item.value === value);

              return (
                <li
                  key={`LabelFilter-${value}`}
                  data-testid={`li--filter-list-${value}`}
                  className={classNames('filter__option', {
                    selected,
                  })}
                  onClick={() => {
                    handleOptionClick(filterOption);
                  }}
                >
                  {selected ? (
                    <Icon
                      name="check"
                      className="filter__check"
                      data-testid={`li--filter-list-icon-selected-indicator-for-${value}`}
                    />
                  ) : (
                    []
                  )}{' '}
                  {text}
                  {iconName ? (
                    <Icon
                      name={iconName}
                      className="filter__icon"
                      data-testid={`li--filter-list-icon-for-${value}`}
                    />
                  ) : (
                    []
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </StyledFilter>
  );
};
