import styled from 'styled-components';

import { ANIMATION, COLORS, SPACING } from 'Permafrost/index';

export const StyledPill = styled.label<{
  $pillTextColor: string;
  $captionColor: string;
  $fixedWidth?: number;
}>`
  display: flex;
  align-items: center;
  font-size: 13px;
  position: relative;
  transition: color ${ANIMATION.duration} ${ANIMATION.timing};

  .caption {
    color: ${(props) => props.$captionColor};
    margin-left: ${SPACING.sm};
  }

  .pill-ellipse {
    align-items: center;
    border: 1px solid ${COLORS.mediumGray};
    border-radius: 2em;
    display: flex;
    justify-content: center;
    margin-top: 2px;
    padding: 0.4em 0.8em 0.5em;
    position: relative;
    width: ${(props) => (props.$fixedWidth ? `${props.$fixedWidth}px` : 'auto')};

    .pill-text {
      color: ${(props) => props.$pillTextColor};
      ${(props) =>
        props.$fixedWidth
          ? ` 
        flex:1;
        display: flex;
        justify-content: center;`
          : ''};
    }

    &.Pill__pill-ellipse--selected {
      border: 2px solid ${COLORS.fieldPromptInfo};
      margin-top: 1px;
      margin-bottom: -1px;
    }

    &.Pill__pill-ellipse--focusRing {
      outline: 2px solid ${COLORS.curiousBlue};
      outline-offset: 5px;
    }
  }

  .checkmark {
    background: ${COLORS.backgroundColor};
    border-radius: 100%;
    color: ${COLORS.fieldPromptInfo};
    pointer-events: none;
    ${(props) =>
      props.$fixedWidth
        ? ` 
          position: absolute; 
          right: 0.8em;
        }`
        : 'margin-left: 0.8em; '};
  }

  &.isDisabled {
    cursor: not-allowed !important;
  }
`;
